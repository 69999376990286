import React, { useState } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";
import clsx from "clsx";
import ArrowDropDownIcon from "../../icons/ArrowDropDown";
import Skeleton from "../../atoms/Skeleton";

interface TableProps<T> {
  columns: any;
  data: T[];
  isLoading?: boolean;
  onRowClick?: (row: T) => void;
  vertical?: boolean;
  onRowHover?: (row: T) => void;
  onRowLeave?: () => void;
  initialState?: Partial<{
    sorting: Array<{ id: string; desc: boolean }>;
    pagination: { pageIndex: number; pageSize: number };
  }>;
}

const Table = <T,>({
  columns,
  data,
  isLoading = true,
  onRowClick,
  vertical = false,
  initialState = {},
  onRowHover,
  onRowLeave,
}: TableProps<T>) => {
  const [hoveredRow, setHoveredRow] = useState<string | null>(null);

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState,
    // initialState: { sorting: [{ id: "date", desc: true }] },
  });

  if (vertical) {
    return (
      <div className="max-h-fit overflow-y-auto">
        <table className="w-full border-collapse overflow-hidden bg-transparent text-sm font-medium">
          <thead>
            <tr className="bg-boundsPurple-500">
              <th className="p-2 border-b border-b-boundsPurple-400 text-white">
                Field
              </th>
              <th className="p-2 border-b border-b-boundsPurple-400 text-white">
                Value
              </th>
            </tr>
          </thead>
          <tbody className="bg-boundsPurple-400 text-white">
            {columns.map(
              (
                column: {
                  header: React.ReactNode;
                  accessorKey: keyof T;
                },
                index: any
              ) => (
                <tr key={`vertical-row-${index}`}>
                  <td className="p-2 border border-boundsPurple-500">
                    {typeof column.header === "string"
                      ? column.header
                      : flexRender(column.header, { column })}
                  </td>
                  <td className="p-2 border border-boundsPurple-500">
                    {isLoading ? (
                      <Skeleton width={"100%"} height={35} borderRadius={10} />
                    ) : (
                      String(data[0]?.[column.accessorKey as keyof T] ?? "N/A")
                    )}
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div className="max-h-[600px] overflow-y-auto">
      <table className="w-full border-collapse overflow-hidden bg-transparent text-sm font-medium">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr
              key={`header-group_${headerGroup.id}`}
              className="bg-boundsPurple-500"
            >
              {headerGroup.headers.map((header, index) => (
                <th
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  className={clsx(
                    "p-2 border-b border-b-boundsPurple-400 bg-boundsPurple-500 cursor-pointer",
                    index === 0 && "rounded-tl-3xl",
                    index === headerGroup.headers.length - 1 && "rounded-tr-3xl"
                  )}
                  style={{
                    width: header.column.columnDef.size,
                  }}
                >
                  <div className="flex justify-between items-center px-2 text-white w-full gap-1">
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    <div className="w-[10px] h-[5px]">
                      {header.column.getIsSorted() ? (
                        header.column.getIsSorted() === "desc" ? (
                          <ArrowDropDownIcon />
                        ) : (
                          <ArrowDropDownIcon className="rotate-180" />
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="bg-boundsPurple-400 text-white">
          {table.getRowModel().rows.map((row, rowIndex) => (
            <tr
              key={row.id}
              onClick={() => onRowClick?.(row.original)}
              style={{ cursor: onRowClick ? "pointer" : "default" }}
              onMouseEnter={() => {
                onRowHover?.(row.original);
                setHoveredRow(row.id);
              }}
              onMouseLeave={() => {
                onRowLeave?.();
                setHoveredRow(null);
              }}
              className={clsx({
                "bg-boundsPurple-100": hoveredRow === row.id,
              })}
            >
              {row.getVisibleCells().map((cell, cellIndex) => (
                <td
                  key={cell.id}
                  className={clsx(
                    "p-2 border border-boundsPurple-500",
                    rowIndex === table.getRowModel().rows.length - 1 &&
                      "border-boundsPurple-500",
                    rowIndex === table.getRowModel().rows.length - 1 &&
                      cellIndex === 0 &&
                      "rounded-bl-lg",
                    rowIndex === table.getRowModel().rows.length - 1 &&
                      cellIndex === row.getVisibleCells().length - 1 &&
                      "rounded-br-lg"
                  )}
                  style={{
                    width: cell.column.columnDef.size,
                  }}
                >
                  {isLoading ? (
                    <Skeleton width={"100%"} height={35} borderRadius={10} />
                  ) : (
                    flexRender(cell.column.columnDef.cell, cell.getContext())
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
