import React, { useState, useEffect } from 'react';
import { Control, RegisterOptions } from 'react-hook-form';
import useDebounce from '../../../../hooks/useDebounce';
import { ISearchFields, ISearchFormValues, IOption } from '../../../../types/search';
import { CreatableSelectComponent } from '../../../atoms/Select';
import CustomMultiValue from '../../../features/Dashboard/MultiValue/MultiValue';

const SearchSelect = ({
  control,
  searchInput,
  isSubmitting,
  title,
  rules,
}: {
  control: Control<ISearchFormValues>;
  searchInput: ISearchFields;
  isSubmitting: boolean;
  title?: string;
  rules?: Omit<RegisterOptions<any, string>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'> | undefined;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchOptions, setSearchOptions] = useState<IOption[]>([]);
  const [queryOffset, setQueryOffset] = useState(0);
  const [inputSearch, setInputSearch] = useState('');

  const debouncedInputSearch = useDebounce(inputSearch, 1000);

  // TODO: Implement this to use our backend API
  // const INPUT_OPTIONS_RESPONSE_COUNT = 50;
  // const getSearchInputOptions = async (input: string, clearExistingOptions?: boolean) => {
  //   try {
  //     setIsLoading(true);

  //     setSearchOptions([]);
  //   } catch (error) {
  //     console.error('Error fetching search input options:', error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const loadMoreOptions = () => {
    if (!isLoading) setQueryOffset(queryOffset + 1);
  };

  const onFocus = () => {
    // if (!debouncedInputSearch) getSearchInputOptions("", true);
  };

  useEffect(() => {
    setSearchOptions([]);
  }, [searchInput.name]);

  useEffect(() => {
    if (!queryOffset && debouncedInputSearch === '') return;
    // getSearchInputOptions(debouncedInputSearch);
  }, [queryOffset, debouncedInputSearch]);

  return (
    <>
      <div>
        {title && <h3 className="text-2xl mb-4">{title}</h3>}
        <CreatableSelectComponent
          control={control}
          name={searchInput.name}
          label={searchInput.label}
          placeholder={searchInput.placeholder}
          isMulti={searchInput.isMulti}
          closeMenuOnSelect={!searchInput.isMulti}
          options={searchInput.options || searchOptions}
          defaultValue={searchInput.defaultValue}
          createOptionPosition="first"
          isSearch={true}
          isSearching={isLoading}
          isDisabled={isSubmitting}
          onMenuScrollToBottom={loadMoreOptions}
          onFocus={onFocus}
          onInputChange={setInputSearch}
          isLoading={isLoading}
          rules={rules}
          MultiValue={(props) => <CustomMultiValue {...props} />}
        />
      </div>
    </>
  );
};

export default SearchSelect;
