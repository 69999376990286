import UniqueValueRenderer from "@arcgis/core/renderers/UniqueValueRenderer";
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol";

export const createHighlightRenderer = (
  selectedSoilCode: string,
  irr_class: string
) => {
  return new UniqueValueRenderer({
    field: "MUSYM",
    uniqueValueInfos: [
      {
        value: selectedSoilCode,
        symbol: new SimpleFillSymbol({
          color: `#${selectedSoilCode}${irr_class}`.slice(0, 7),
          outline: {
            color: `#${selectedSoilCode}${irr_class}`.slice(0, 7),
            width: 2,
          },
        }),
      },
    ],
    defaultSymbol: new SimpleFillSymbol({
      color: [200, 200, 200, 0.2],
      outline: {
        color: "#CCCCCC",
        width: 1,
      },
    }),
  });
};
