import React, { FC, useEffect, useState } from "react";
import InfiniteScrollTable from "../InfiniteScrollTable/InfiniteScrollTable";
import { IColumn } from "../../features/Dashboard/DashboardTable/DashboardTable";
import { IGetMortgageHoldersParams } from "../../../services/dashboard";
import { IRateAnalysisParams } from "../../../types/mortgage";
import { IUccSearchParams, IUccSearchResponse } from "../../../types/ucc";

export interface ISort {
  by: string;
  order: "asc" | "desc";
}

type IFetchData =
  | ((
      params: IGetMortgageHoldersParams
    ) => Promise<{ data: any[]; total_count?: number }>)
  | ((params: IUccSearchParams) => Promise<IUccSearchResponse | null>);
interface IInfiniteScrollTableProvider {
  columns: IColumn[];
  fetchData: IFetchData;

  initialParams?:
    | IGetMortgageHoldersParams
    | IRateAnalysisParams
    | IUccSearchParams;
  pageSize?: number;
  showIndex?: boolean;
  sort?: ISort;
  handleSort?: (field: string) => void;
  className?: string;
}

const InfiniteScrollTableProvider: FC<IInfiniteScrollTableProvider> = ({
  columns,
  fetchData,
  initialParams,
  pageSize = 20,
  showIndex,
  sort,
  handleSort,
  className,
}) => {
  const [rows, setRows] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState<number | undefined>(undefined);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const loadMoreData = async (currentPage: number) => {
    if (!initialParams) return;

    setIsLoading(true);

    const requestParams = {
      ...initialParams,
      page: currentPage,
      page_size: pageSize,
      include_count: currentPage === 1,
      ...(sort && "sort_by" in initialParams ? { sort_by: sort?.by } : {}),
      ...(sort && "sort_order" in initialParams
        ? { sort_order: sort?.order }
        : {}),
    };

    const response = await fetchData(requestParams as any);

    if (response) {
      setRows((prev) => [...prev, ...response.data]);

      if (currentPage === 1 && response.total_count !== undefined) {
        setTotalCount(response.total_count);
      }

      if (response.data.length < pageSize) {
        setHasMore(false);
      } else {
        setPage(currentPage + 1);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const fetchDataOnParamsChange = async () => {
      if (
        !initialParams ||
        Object.values(initialParams).some((value) => value === undefined)
      ) {
        return;
      }

      setRows([]);
      setHasMore(true);
      setPage(1);
      await loadMoreData(1);
    };

    fetchDataOnParamsChange();
  }, [initialParams]);

  return (
    <>
      <InfiniteScrollTable
        columns={columns}
        rows={rows}
        loadMoreData={() => loadMoreData(page)}
        hasMore={hasMore}
        isLoading={isLoading}
        showIndex={showIndex}
        sort={sort}
        handleSort={handleSort}
        className={className}
      />
    </>
  );
};

export default InfiniteScrollTableProvider;
