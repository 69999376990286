import { ColumnDef } from "@tanstack/react-table";
import React, { useMemo } from "react";
import { formatMoney } from "../../../utils/string";
import { IMortgageHolder } from "../../../types/financialOverview";
import Table from "../../molecules/Table";
import { formatDate } from "../../../utils/date";

const MortgageHolderTable = ({
  data,
  isLoading,
  onRowClick,
}: {
  data: IMortgageHolder[];
  isLoading: boolean;
  onRowClick: (row: any) => void;
}) => {
  const columns = useMemo<ColumnDef<IMortgageHolder>[]>(
    () => [
      {
        accessorKey: "date",
        header: "Date",
        size: 120,
        cell: ({ getValue }) => {
          const value = getValue() as string;
          return <>{formatDate(value)}</>;
        },
      },
      {
        accessorKey: "loan_institution",
        header: "From",
        cell: (info) => info.renderValue(),
        size: 100,
      },
      { accessorKey: "borrower", header: "To", size: 135 },
      {
        accessorKey: "county",
        header: "County",
        size: 120,
      },
      {
        accessorKey: "city",
        header: "City",
        size: 120,
      },
      { accessorKey: "description", header: "Description", size: 120 },
      {
        accessorKey: "mortgage_amount",
        header: "Mortgage",
        size: 150,
        cell: ({ getValue }) => {
          const value = getValue();
          return <>{formatMoney(value as number)}</>;
        },
      },
    ],
    []
  );

  return (
    <Table
      columns={columns}
      data={data}
      isLoading={isLoading}
      onRowClick={onRowClick}
      initialState={{ sorting: [{ id: "date", desc: true }] }}
    />
  );
};

export default MortgageHolderTable;
