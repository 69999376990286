import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import CheckboxGroup from "../../../molecules/CheckboxGroup";
import annualFloodHazardPoint2 from "../../../../assets/images/annual_flood_hazard_0_point_2_percent.png";
import annualFloodHazard1Percent from "../../../../assets/images/annual_flood_hazard_1_percent.png";
import reducedRiskDueToLevee from "../../../../assets/images/reduced_risk_due_to_levee.png";
import futureAnnualFloodHazard1Percent from "../../../../assets/images/future_annual_flood_hazard_1_percent.png";
import floodwayZone from "../../../../assets/images/floodway_zone.png";
import wetlandsArea from "../../../../assets/images/wetlands_area.png";
import useBoundsUI from "../../../../hooks/useBoundsUI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons'; 
import { Tooltip } from "react-tooltip";
interface FormValues {
  checkboxes: string[];
}

const options = [
  {
    id: 1,
    label: (
      <div className="flex gap-3  h-5 w-full">
        <img src={annualFloodHazardPoint2} alt="0.2% Annual flood hazard" /> 0.2% Annual flood hazard{" "}
      </div>
    ),
    value: "PTTwoLayer",
  },
  {
    id: 2,
    label: (
      <div className="flex gap-3  h-5 w-full">
        <img src={annualFloodHazard1Percent} alt="1% Annual flood hazard" />
        1% Annual flood hazard
      </div>
    ),
    value: "OneAnnualLayer",
  },
  {
    id: 3,
    label: (
      <div className="flex gap-3  h-5 w-full">
        <img src={reducedRiskDueToLevee} alt="Reduced risk due to levee" />
        Reduced risk due to levee{" "}
      </div>
    ),
    value: "ReducedRiskLayer",
  },
  {
    id: 4,
    label: (
      <div className="flex gap-3  h-5 w-full">
        <img src={futureAnnualFloodHazard1Percent} alt="Future 1% Annual flood hazard" />
        Future 1% Annual flood hazard
      </div>
    ),
    value: "FutureLayer",
  },
  {
    id: 5,
    label: (
      <div className="flex gap-3  h-5 w-full">
        <img src={floodwayZone} alt="Floodway" />
        Floodway{" "}
      </div>
    ),
    value: "FloodwayLayer",
  },
  {
    id: 6,
    label: (
      <div className="flex gap-3  h-5 w-full">
        <img src={wetlandsArea} alt="Wetlands" />
        Wetlands{" "}
      </div>
    ),
    value: "WetlandsLayer",
  },
];

const Flood = () => {
  const {
    featureLayer: { setGroup },
  } = useBoundsUI();

  const { control, setValue, watch } = useForm<FormValues>({
    defaultValues: { checkboxes: [] },
  });
  const selectedValues = watch("checkboxes");

  useEffect(() => {
    setGroup({ groupName: "WaterLayer", layers: selectedValues });
    selectedValues.length == 0
      ? setGroup({ groupName: "WaterLayer", layers: null })
      : "";
  }, [selectedValues]);

  // Unmount
  useEffect(
    () => () => setGroup({ groupName: "WaterLayer", layers: null }),
    []
  );

  return (
    <div className="min-w-[436px]">
      <div className="flex flex-row items-center border-b-[1px] border-boundsYellow-50 pb-5 mb-6">
          <h2 className="text-[32px]  mr-1">Flood</h2>
          <FontAwesomeIcon className="question-mark" icon={faCircleQuestion} />
          <Tooltip anchorSelect=".question-mark" place="bottom" offset={10}>
            Source: FEMA/Fish and Wildlife
          </Tooltip>
			</div>
      <CheckboxGroup
        control={control}
        name="checkboxes"
        options={options}
        setValue={setValue}
        showAll={true}
      />
    </div>
  );
};

export default Flood;
