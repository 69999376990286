import axios from "../api";
import { ElevationData, ITax, SoilData } from "../types/insights";

export const getCropDataByParcel = async (parcel_lid: string): Promise<any> => {
  try {
    const response = await axios.get(
      "/api/cdl/by-parcel?parcel_lid=" + parcel_lid
    );
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 401) {
      return error.response?.data;
    }
    return null;
  }
};

export const getYieldData = async (county: string): Promise<any> => {
  try {
    const response = await axios.get("/api/crop-yield?county=" + county);
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 401) {
      return error.response?.data;
    }
    return null;
  }
};

export const getPropertyTaxByAssessment = async (
  assessment_lid: string
): Promise<ITax[]> => {
  const response = await axios.get(
    `/api/tax/by-assessment?primary_assessment_lid=${assessment_lid}`
  );
  return response.data;
};

export const getElevationDataByParcelId = async (
  parcel_lid: string
): Promise<ElevationData | null> => {
  try {
    const response = await axios.get(
      `/api/elevation-insights/?parcel_lid=${parcel_lid}`
    );
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 401) {
      return error.response?.data;
    }
    return null;
  }
};

export const getSoilData = async (parcel_lid: string): Promise<SoilData[]> => {
  try {
    const response = await axios.get(
      `/api/soil-insights?parcel_lid=${parcel_lid}`
    );
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 401) {
      return error.response?.data;
    }
    return [];
  }
};
