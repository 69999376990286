import { Dispatch, SetStateAction, createContext } from "react";
import Point from "@arcgis/core/geometry/Point";
import MapView from "@arcgis/core/views/MapView";
import Graphic from "@arcgis/core/Graphic";
import { DataOption } from "../types/insights";

export interface IAttributes {
  OBJECTID: number;
  PARCEL_LID: string;
  PARCEL_APN: string;
  PRIMARY_ASSESSMENT_LID: string;
  UNIQUE_TAXAPN: string;
  OWNER_NAME: string;
  OWNER_NAME_1: string;
  Legal_Full: string;
  COUNTY: string;
  SECTION: string;
  TOWNSHIP: string;
  RANGE: string;
  total_owned_acreage: number;
  total_mortgage_acreage: number;
  total_mortgage_amount: number;
  ACREAGE: number;
  Min_: number;
  Max_: number;
  loan_institution: string;
  Mortgage: string;
  mortgage_date?: string;
  mortgage_amount?: number;
  UCC: string;
  date_assr_tape_cut?: string;
  MUSYM?: string;
}

export interface IParcel {
  attributes: IAttributes;
  county: string;
  geometry?: __esri.Geometry;
  graphic: Graphic;
  label: {
    id: number;
    graphic: Graphic;
  };
}

export interface IGroup {
  groupName: string;
  layers: string[] | null;
}

export interface IBoundsUIContext {
  sidebarMenu: {
    isSubmenuOpen: boolean;
    setIsSubmenuOpen: Dispatch<SetStateAction<boolean>>;
    widgetType: string;
    setWidgetType: Dispatch<SetStateAction<string>>;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
  };
  featureLayer: {
    group: IGroup | null;
    setGroup: React.Dispatch<React.SetStateAction<IGroup | null>>;
  };
  searchResults: {
    point: Point | null;
    setPoint: React.Dispatch<React.SetStateAction<Point | null>>;
  };
  mainMapView: {
    view: MapView | null;
    setView: React.Dispatch<React.SetStateAction<MapView | null>>;
  };

  //selection of map pieces
  selection: {
    parcels: IParcel[];
    setParcels: React.Dispatch<React.SetStateAction<IParcel[]>>;
  };
  insightData: {
    insightType: DataOption | null;
    setInsightType: React.Dispatch<React.SetStateAction<DataOption | null>>;
    data: any[] | null;
    setData: React.Dispatch<React.SetStateAction<any[] | null>>;
    insightError: any;
    setInsightError: Dispatch<SetStateAction<any>>;
  };

  /* UI dashboard */
  dashboard: {
    isMortgageTableLoading: boolean;
    setIsMortgageTableLoading: Dispatch<SetStateAction<boolean>>;
  };
}

export const BoundsUIContext = createContext<IBoundsUIContext | undefined>(
  undefined
);
