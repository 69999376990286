export enum DataOption {
  CropHistory = "cropHistory",
  PropertyTax = "propertyTax",
  Yield = "yield",
  Flood = "flood",
  Elevation = "elevation",
  WaterWells = "waterWells",
  Soils = "soils",
  Aircraft = "aircraft",
}

export interface ITax {
  assessment_lid: string;
  apn: string;
  year: number;
  amount: number;
  tax_year_delinq?: number;
}

/* Elevation */
export interface ElevationData {
  min_slope: number;
  max_slope: number;
  mean_slope: number;
  min_elevation: number;
  max_elevation: number;
}

export interface SoilData {
  soil_code: string;
  soil_description: string;
  percent_field: number;
  nccpi_score: number;
  nccpi_corn: number;
  nccpi_soy: number;
  nccpi_cotton: number;
  nccpi_sorghum: number;
  non_irr_class: string;
  irr_class: string;
  drainage_class: string;
  farmland_class: string;
}
