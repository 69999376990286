import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PopupState {
  selectedParcelLId: string | null;
}

const loadFromLocalStorage = (): PopupState => {
  const storedState = localStorage.getItem("popupState");
  return storedState ? JSON.parse(storedState) : { selectedParcelLId: null };
};

const initialState: PopupState = loadFromLocalStorage();

const broadcast = new BroadcastChannel("redux-sync");

const popupSlice = createSlice({
  name: "popup",
  initialState,
  reducers: {
    setSelectedParcelLId: (state, action: PayloadAction<string | null>) => {
      state.selectedParcelLId = action.payload;

      localStorage.setItem("popupState", JSON.stringify(state));

      try {
        broadcast.postMessage({
          type: "SYNC_STATE",
          payload: JSON.parse(JSON.stringify(state)),
        });
      } catch (error) {
        console.error("BroadcastChannel postMessage error:", error);
      }
    },
  },
});

broadcast.onmessage = (event) => {
  if (event.data.type === "SYNC_STATE") {
    const newState = event.data.payload;

    localStorage.setItem("popupState", JSON.stringify(newState));

    window.location.reload();
  }
};

export const { setSelectedParcelLId } = popupSlice.actions;
export default popupSlice.reducer;
