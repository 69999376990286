import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { IColumn } from "../../features/Dashboard/DashboardTable/DashboardTable";
import clsx from "clsx";
import Skeleton from "../../atoms/Skeleton";
import { ISort } from "../InfiniteScrollTableProvider/InfiniteScrollTableProvider";
import ArrowDropDownIcon from "../../icons/ArrowDropDown";

const InfiniteScrollTable = ({
  columns,
  rows,
  loadMoreData,
  hasMore,
  isLoading,
  showIndex = true,
  className,
  handleSort,
  sort,
}: {
  columns: IColumn[];
  rows: any[];
  loadMoreData: any;
  hasMore: boolean;
  isLoading: boolean;
  showIndex?: boolean;
  className?: string;
  handleSort?: (field: string) => void;
  sort?: ISort;
}) => {
  return (
    <div
      className={clsx("mt-2 overflow-auto", className)}
      id="scroll-container"
    >
      <InfiniteScroll
        dataLength={rows.length}
        next={loadMoreData}
        hasMore={hasMore}
        loader={
          isLoading && <Skeleton width={"100%"} height={50} borderRadius={0} />
        }
        endMessage={
          <p className="border-y-[1px] border-boundsYellow-50 py-5 mt-6 text-center">
            No more results.
          </p>
        }
        scrollableTarget="scroll-container"
      >
        <table
          className="w-auto min-w-full border-b-[1px] border-boundsPurple-100 rounded-m table-fixed"
          style={{ maxWidth: "100%" }}
        >
          <thead className="sticky top-0 bg-boundsPurple-100 shadow-sm">
            <tr>
              {showIndex && (
                <th className="p-[10px] border-b-[1px] border-boundsPurple-100">
                  #
                </th>
              )}
              {columns.map((col, colIndex) => (
                <th
                  key={"header-col-" + colIndex}
                  className="p-[10px] border-b-[1px] border-boundsPurple-100 "
                  style={{
                    minWidth: col.minWidth || 10,
                    maxWidth: col.maxWidth || "none",
                  }}
                  onClick={() => {
                    col.sortField && handleSort && handleSort(col.sortField);
                  }}
                >
                  <div className="flex justify-between items-center gap-3">
                    {col.header}
                    {sort && sort.by === col.sortField && (
                      <div
                        className={clsx(
                          "w-3 xl:w-5",
                          sort.order === "asc" && "rotate-180"
                        )}
                      >
                        <ArrowDropDownIcon />
                      </div>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {rows.map((row: any, rowIndex: number) => (
              <tr key={"row-" + rowIndex} className="h-[70px] 3xl:h-[90px]">
                {showIndex && (
                  <td className="p-[10px] border-t-[1px] border-t-boundsPurple-100 border-r-[1px] border-boundsPurple-100">
                    {rowIndex + 1}
                  </td>
                )}
                {columns.map((col, colIndex) => {
                  const extra: { [key: string]: any } = {};
                  if (col.additionalFields) {
                    col.additionalFields.forEach((field: string) => {
                      extra[field] = row[field];
                    });
                  }
                  return (
                    <td
                      key={"row-" + rowIndex + "-col-" + colIndex}
                      className="p-[10px] border-t-[1px] border-t-boundsPurple-100 border-r-[1px] border-boundsPurple-100"
                      style={{
                        minWidth: col.minWidth || 10,
                        maxWidth: col.maxWidth || "none",
                        textOverflow: col.maxWidth ? "ellipsis" : undefined,
                      }}
                      title={col.maxWidth ? row[col.field] : undefined}
                    >
                      {(col.transform
                        ? col.transform(row[col.field], {
                            ...extra,
                            rowData: row,
                          })
                        : row[col.field]) || "No Data"}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </InfiniteScroll>
    </div>
  );
};

export default InfiniteScrollTable;
