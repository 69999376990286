import axios from "../api";
import { AircraftData } from "../types/aircraft";
export type AircraftResponse =
  | AircraftData
  | { error: boolean; message: string };

export const getAircraftData = async (
  objectId: number
): Promise<AircraftResponse> => {
  try {
    const response = await axios.get(`/api/aircraft?objectid=${objectId}`);
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 401) {
      return error.response?.data;
    }
    return {
      error: true,
      message: error.message || "An unexpected error occurred",
    };
  }
};
