import React, { useEffect, useState } from "react";
import useBoundsUI from "../../../../hooks/useBoundsUI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import { Tooltip } from "react-tooltip";
import Table from "../../../molecules/Table";
import { AircraftData } from "../../../../types/aircraft";
import {
  AircraftResponse,
  getAircraftData,
} from "../../../../services/aircraft";
import { Parcellightboxlayer } from "../../../../layers/Layers";

const columns = [
  { accessorKey: "county", header: "County" },
  { accessorKey: "state", header: "State" },
  { accessorKey: "city", header: "City" },
  { accessorKey: "address", header: "Address" },
  { accessorKey: "house_number", header: "House Number" },
  { accessorKey: "prefix_direction", header: "Prefix Direction" },
  { accessorKey: "prefix_type", header: "Prefix Type" },
  { accessorKey: "street_name", header: "Street Name" },
  { accessorKey: "suffix_type", header: "Suffix Type" },
  { accessorKey: "suffix_direction", header: "Suffix Direction" },
  { accessorKey: "unit", header: "Unit" },
  { accessorKey: "zip", header: "ZIP Code" },
  { accessorKey: "n_number", header: "N-Number" },
  { accessorKey: "serial_number", header: "Serial Number" },
  { accessorKey: "mfr_mdl_code", header: "Manufacturer Model Code" },
  { accessorKey: "eng_mfr_mdl", header: "Engine Manufacturer Model" },
  { accessorKey: "year_mfr", header: "Year Manufactured" },
  { accessorKey: "type_registrant", header: "Type Registrant" },
  { accessorKey: "name", header: "Name" },
  { accessorKey: "region", header: "Region" },
  { accessorKey: "last_action_date", header: "Last Action Date" },
  { accessorKey: "cert_issue_date", header: "Certification Issue Date" },
  { accessorKey: "certification", header: "Certification" },
  { accessorKey: "type_aircraft", header: "Type Aircraft" },
  { accessorKey: "type_engine", header: "Type Engine" },
  { accessorKey: "status_code", header: "Status Code" },
  { accessorKey: "mode_s_code", header: "Mode S Code" },
  { accessorKey: "fract_owner", header: "Fractional Owner" },
  { accessorKey: "air_worth_date", header: "Airworthiness Date" },
  { accessorKey: "other_names_1_field", header: "Other Names 1" },
  { accessorKey: "other_names_2_field", header: "Other Names 2" },
  { accessorKey: "other_names_3_field", header: "Other Names 3" },
  { accessorKey: "other_names_4_field", header: "Other Names 4" },
  { accessorKey: "other_names_5_field", header: "Other Names 5" },
  { accessorKey: "expiration_date", header: "Expiration Date" },
  { accessorKey: "unique_id", header: "Unique ID" },
  { accessorKey: "kit_mfr", header: "Kit Manufacturer" },
  { accessorKey: "kit_model", header: "Kit Model" },
  { accessorKey: "mode_s_code_hex", header: "Mode S Code Hex" },
];

const Aircraft = () => {
  const {
    featureLayer: { setGroup },
    insightData: { data, insightError },
  } = useBoundsUI();
  const [aircraftData, setAircraftData] = useState<AircraftData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    Parcellightboxlayer.labelingInfo[0].labelExpressionInfo.expression = "";
    setGroup({
      groupName: "AircraftGroupLayer",
      layers: ["AircraftLayer"],
    });

    return () => {
      Parcellightboxlayer.labelingInfo[0].labelExpressionInfo.expression = "$feature.Owner_Name";
      setGroup({ groupName: "AircraftGroupLayer", layers: null });
    }
  }, []);

  const isErrorResponse = (
    response: AircraftResponse
  ): response is { error: boolean; message: string } => {
    return (
      (response as { error: boolean; message: string }).error !== undefined
    );
  };

  useEffect(() => {
    const fetchAircraft = async (objectId: number) => {
      try {
        setIsLoading(true);
        const response = await getAircraftData(objectId);

        if (isErrorResponse(response)) {
          console.error("Error fetching aircraft data:", response.message);
          setAircraftData([]);
        } else {
          setAircraftData([response]);
        }
      } catch (error) {
        console.error("Error fetching aircraft data:", error);
        setAircraftData([]);
      } finally {
        setIsLoading(false);
      }
    };

    if (data && "OBJECTID" in data) {
      fetchAircraft(data["OBJECTID"] as number);
    }
  }, [data]);

  return (
    <div className="min-w-[436px]">
      <div className="flex flex-row items-center">
        <h2 className="text-[32px]  mr-1">Aircraft</h2>
        <FontAwesomeIcon className="question-mark" icon={faCircleQuestion} />
        <Tooltip
          className="max-w-[400px]"
          anchorSelect=".question-mark"
          place="bottom"
          offset={10}
        >
          Federal Aviation Administration
        </Tooltip>
      </div>
      <a className='underline text-blue-600' href='https://registry.faa.gov/aircraftinquiry/Search/NameInquiry' target='_blank' rel='noreferrer'>FAA Aircraft Inquiry</a>
      {insightError ? (
        <p className="border-y-[1px] border-boundsYellow-50 py-5">
          {insightError}
        </p>
      ) : isLoading ? (
        <p className="border-y-[1px] border-boundsYellow-50 py-5">
          Loading...
        </p>
      ) : aircraftData.length > 0 ? (
        <Table
          columns={columns}
          data={aircraftData}
          vertical={true}
          isLoading={isLoading}
        />
      ) : (
        <>
          <p className="border-y-[1px] border-boundsYellow-50 py-5 ">
            Select an area to view data
          </p>
        </>
      )}
    </div>
  );
};

export default Aircraft;
